.INVOICE_FORM_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  width: 98%;
  min-width: 1000px;
  border-radius: 15px;
  padding: 1em;
}

.INVOICE_FORM_HEADER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  margin-bottom: 1em;
  font-family: sans-serif;
  color: var(--header-color);
}

/* ################### */
.INVOICE_FORM_INFO_CONTAINER {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 400px;
  min-height: 400px;
  border-radius: 15px;
  margin-bottom: 2em;
}
.INVOICE_FORM_INVOICE_IDENTITY {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
  height: 100%;
  background-color: var(--input-section-background);
  border-radius: 15px;
  padding-top: 1em;
}
.INVOICE_FORM_INVOICE_TO_DETAILS {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
  height: 100%;
  /* border: 1px solid #2c3e50; */
  border-radius: 15px;
  background-color: var(--input-section-background);
  padding-top: 1em;
}
.INVOICE_FORM_INPUT_FIELD_CONTAINER {
  padding: 5px;
  margin-bottom: 5px;
  width: 90%;
}

.INVOICE_FORM_INPUT_CHECK_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.INVOICE_FORM_INPUT_FIELD {
  color: var(--font-color) !important;
  border-radius: 10px !important;
  /* height: 50px !important; */
  border: 0.01em solid var(--border-color) !important;
}
.INVOICE_FORM_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--input-section-background) inset !important;
  -webkit-text-fill-color: var(--font-color);
}

.INVOICE_FORM_INPUT_FIELD_LABEL {
  color: var(--field-label) !important;
  z-index: 10 !important;
  background-color: var(--input-section-background);
  padding-right: 10px !important;
  padding-left: 2px !important;
}
.INVOICE_FORM_ORDER_DETAILS {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
  height: 100%;
  border-radius: 15px;
  background-color: var(--input-section-background);
  padding-top: 1em;
}

/* ############## */
.INVOICE_FORM_ITEMS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 400px;
  height: 400px;
  border-radius: 15px;
  margin-bottom: 2em;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: var(--input-section-background);
}

.INVOICE_FORM_ITEM_DESCRIPTION_HEADER {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--header-color);
  border: 1px solid var(--border-color);
}

.INVOICE_FORM_ITEM_RATE_HEADER {
  display: flex;
  width: 20%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--secondary-font-color);
  border: 1px solid var(--border-color);
}

.INVOICE_FORM_ITEM_QTY_HEADER {
  display: flex;
  width: 10%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--secondary-font-color);
  border: 1px solid var(--border-color);
}
.INVOICE_FORM_ITEM_AMOUNT_HEADER {
  display: flex;
  width: 20%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--secondary-font-color);
  border: 1px solid var(--border-color);
}
.INVOICE_FORM_ITEMS_CONTAINER {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 350px;
  height: max-content;
  margin-top: 1em;
  border-bottom: 2px solid var(--border-color);
}

.INVOICE_FORM_ITEM_ADD {
  display: flex;
  flex-direction: column;
  width: 10%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  font-size: 2rem;
}

.INVOICE_FORM_ITEM_DESCRIPTION {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--secondary-font-color);
}
.INVOICE_FORM_ITEM_RATE {
  display: flex;
  width: 20%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--secondary-font-color);
}
.INVOICE_FORM_ITEM_QTY {
  display: flex;
  width: 10%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--secondary-font-color);
  /* border: 1px solid rgb(204, 183, 247); */
}
.INVOICE_FORM_ITEM_AMOUNT {
  display: flex;
  width: 20%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--secondary-font-color);
}

.INVOICE_FORM_SAVED_ITEMS_CONTAINER {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100px;
  margin-top: 1em;
  margin-bottom: 1em;
  border-bottom: 2px solid var(--border-color);
  transition: all 1s ease-in-out;
}

.INVOICE_FORM_SAVED_ITEM_DELETE {
  display: flex;
  flex-direction: column;
  width: 10%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  font-size: 2rem;
}
.INVOICE_FORM_SAVED_ITEM_DESCRIPTION {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
  color: var(--secondary-font-color);
}
.INVOICE_FORM_SAVED_ITEM_DESCRIPTION span {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--font-color);
}

.INVOICE_FORM_SAVED_ITEM_RATE {
  display: flex;
  width: 20%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--input-section-background);
}
.INVOICE_FORM_SAVED_ITEM_QTY {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 10%;
  height: 100%;
  color: var(--secondary-font-color);
}
.INVOICE_FORM_SAVED_ITEM_AMOUNT {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 20%;
  height: 100%;
  color: var(--secondary-font-color);
}

/* ################################ */
.INVOICE_FORM_PAYMENT_AMOUNTS_CONTAINERS {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 300px;
  height: max-content;
  border-radius: 15px;
  margin-bottom: 2em;
}

.INVOICE_FORM_PAYMENT_AMOUNTS {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 300px;
  min-height: 300px;
  /* border: 1px solid #2c3e50; */
  border-radius: 15px;
  background-color: var(--input-section-background);
}

.INVOICE_FORM_SUBTOTAL {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  /* border: 1px solid #6b778d; */
  height: 20%;
}
.INVOICE_FORM_VAT_TOTAL {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  /* border: 1px solid #6b778d; */
  height: 20%;
}
.INVOICE_FORM_TOTAL {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  /* border: 1px solid #6b778d; */
  height: 20%;
}
.INVOICE_FORM_PAYMENTS {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  /* border: 1px solid #6b778d; */
  height: 20%;
}
.INVOICE_FORM_BALANCE_DUE {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 20%;
  background-color: var(--input-section-background);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.INVOICE_FORM_AMOUNT_LABLE {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
  color: var(--field-label);
  font-size: 1.2rem;
  font-family: sans-serif;
  font-weight: bold;
}
.INVOICE_FORM_AMOUNT_VALUE {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
  color: var(--secondary-font-color);
  font-size: 1.5rem;
  font-family: Roboto;
  /* background-color: #2c3e50; */
}

/* Banking Details ----------*/

.INVOICE_FORM_BANKING_DETAILS {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 45%;
  min-height: 300px;
  height: max-content;
  background-color: var(--input-section-background);
  border-radius: 15px;
  padding-top: 1em;
  transition: all 1s ease-in-out;
}

.INVOICE_FORM_INPUT_BANKING_FIELD_CONTAINER {
  padding: 5px;
  margin-bottom: 5px;
  width: 50%;
}

.INVOICE_FORM_BANKING_DETAILS_BUTTONS {
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: center;
  justify-content: space-around;
  top: 0em;
  left: 0em;
  width: 100px;
  height: 50px;
  z-index: 10;
}

.INVOICE_FORM_BANK {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 15%;
  padding-top: 1em;
}

.INVOICE_FORM_ACCOUNT_NAME {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 15%;
}

.INVOICE_FORM_ACCOUNT_NO {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 20%;
}

.INVOICE_FORM_BRANCH_NO {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 20%;
}

.INVOICE_FORM_BANK_NOTE {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 20%;
}

.INVOICE_FORM_BANKING_DETAILS_LABLE {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  height: 100%;
  color: var(--field-label);
  font-family: sans-serif;
  font-weight: bold;
}
.INVOICE_FORM_BANKING_DETAILS_VALUE {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  height: 100%;
  color: var(--font-color);
}
.INVOICE_FORM_TERMS_CONDITIONS_CONTAINER {
  display: none;
  width: 100%;
  min-height: 400px;
  border: 1px solid var(--border-color);
  border-radius: 15px;
}

.INVOICE_FORM_TERMS_CONDITIONS {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  border: 1px solid var(--border-color);
  border-radius: 15px;
}

.INVOICE_FORM_ACTIONS {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  min-height: 100px;
  border-top: 1px dashed var(--border-color);
  margin-block: 2em;
}

.INVOICE_FORM_GENERATE_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 100%;
}
.INVOICE_FORM_PRINT_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 100%;
  margin-inline: 1em;
}
