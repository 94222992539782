.NOTIFICATION_ITEM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  min-height: 100px;
  width: 300px;
  border-radius: 10px;
  padding: 5px;
}
.NOTIFICATION_ITEM_HEADER {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: inherit;
}
.NOTIFICATION_ITEM_HEADER span {
  font-size: 1.2rem;
  font-family: Arial;
  color: #eeb76b;
}
.NOTIFICATION_ITEM_BODY {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.NOTIFICATION_ITEM_BODY p {
  font-size: 1rem;
  font-family: Arial;
  color: #eeeeee;
}
.NOTIFICATION_ITEM_FOOTER {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: inherit;
}
.NOTIFICATION_ITEM_FOOTER span {
  font-size: 0.8rem;
}
