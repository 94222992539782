.RECEPTION_JOB_TASK_SEARCH_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 99%;
  height: max-content;
}
.RECEPTION_JOB_TASK_SEARCH_BAR_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  min-width: 200px;
  min-height: 40px;
  border-radius: 10px !important;
  border: 1px solid var(--border-color) !important;
}
.RECEPTION_JOB_TASK_DATE_SEARCH_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
}
.RECEPTION_JOB_TASK_SEARCH_INPUT_FIELD {
  color: var(--font-color) !important;
  border-radius: 15px !important;
  border: 1px solid var(--border-color) !important;
  z-index: 0;
}

.RECEPTION_JOB_TASK_TEXT_SEARCH_INPUT_FIELD:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--background1) inset !important;
  -webkit-text-fill-color: var(--font-color);
}

.RECEPTION_JOB_TASK_SEARCH_INPUT_FIELD_LABEL {
  color: var(--field-color) !important;
  z-index: 10 !important;
  background-color: var(--background1);
  padding-right: 10px !important;
  padding-left: 2px !important;
}

.RECEPTION_JOB_TASK_SEARCH_ITEMS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: max-content;
  padding: 1px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
