.JOB_CARD_EDIT_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 100%;
  /* min-height: 99%;  */
  min-height: 900px;
  padding-top: 15px;
  transition: all 1s ease-in-out;
}
/* .JOB_CARD_FORM_S {
  display: flex;
  flex-direction: column;
} */
/* .JOB_CARD_FORM_CLIENT {
  display: flex;
  flex-direction: column;
  margin-top: 72px;
  height: max-content;
}
.JOB_CARD_FORM_INSURANCE {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  height: max-content;
}
.JOB_CARD_FORM_DEVICE {
  display: flex;
  flex-direction: column;
  height: max-content;
}
.JOB_CARD_FORM_DESCRIPTION_CONTAINER {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: max-content;
 
} */
.JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER {
  padding: 5px;
  margin-bottom: 5px;
}
.BOOK_DEVICE_IMAGE {
  display: flex;
  /* align-items: center; */
  min-width: 1200;
  max-width: 1200;
  min-height: 290px;
  /* border: 1px solid #2c3e50; */
  border-radius: 20px;
  padding: 5px;
  padding-top: 20px;
}
.ADD_IMAGE_BUTTON {
  position: relative;
  overflow: hidden;
  display: inline-block;
  height: 100px;
  min-width: 100px;
  margin-left: 10px;
  border: 1px solid #2c3e50;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  font-size: 2.9rem;
  color: #6b778d;
}
.ADD_IMAGE_BUTTON:hover {
  cursor: pointer;
  border-radius: 50px;
}
.ADD_IMAGE_BUTTON input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.UPLOAD_FILE_ICON {
  display: flex;
  width: inherit;
  height: inherit;
  align-items: center;
  justify-content: center;
}
.JOB_CARD_EDIT_LOADING_IMAGES_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  /* width: 85%;
  max-width: 85%; */
  min-height: inherit;
  max-height: 400px;
  border: 1px solid #2c3e50;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
}
.JOB_CARD_EDIT_UPLOADED_IMAGES_CONTAINER {
  display: grid;
  grid-auto-flow: column;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  grid-auto-rows: minmax(200px, auto);
  grid-row-gap: 100px;
  padding-left: 10px;
  width: 1000px;
  max-width: 1000px;
  min-height: inherit;
  max-height: 400px;
  border: 1px solid #2c3e50;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.JOB_CARD_EDIT_UPLOADED_IMAGE_CONTAINER {
  display: flex;
  height: max-content;
  padding: 5px;
}
.BOOK_DEVICE_FORM_ACTION {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 20px;
}
.PROGRESS_CIRCLE_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}

.MODAL_BODY {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 250px;
  background-color: #2c3e50;
  /* border: 2px solid rgb(41, 40, 40); */
  box-shadow: 2px 2px 20px #1e272e;
  padding: 30px;
  border-radius: 20px;
  opacity: 0.95;
  transition: all 1s ease-in-out;
}
.UPLOAD_FILE_MODAL_HEADER {
  color: #e9ec17;
}
.DELETE_MODAL_ACTIONS {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 200px;
}
.UPLOAD_FILE_MODAL_BODY p {
  color: #b4b4b4;
  text-align: center;
  font-family: Roboto;
}
.UPLOAD_FILE_MODAL_ACTIONS {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 80px;
  height: 20px;
  border-radius: 20px;
  box-shadow: 1px 1px 10px #1e272e;
}
.UPLOAD_FILE_MODAL_ACTIONS h6 {
  color: #7a7a7a;
  font-family: Arial;
  /* margin-top: 5px; */
  height: 10px;
}
.UPLOAD_FILE_MODAL_ACTIONS:hover {
  cursor: pointer;
  color: #fdfbfb;
  transition: all 1s ease-in-out;
  transform: scale(1.1);
}
