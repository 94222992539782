.USER_PROFILE_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  min-width: 1000px;
  height: max-content;
  background-color: #0b0f19;
}

.USER_PROFILE_PICTURE_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 1200px;
  min-width: 700px;
  height: 350px;
  min-height: 350px;
  margin-top: 150px;
  /* border: 1px solid #111827; */
  border-radius: 20px;
}

.USER_PROFILE_PICTURE {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  min-width: 350px;
  height: 350px;
  min-height: 300px;
  margin-right: auto;
  border-radius: 20px;
  /* background-color: #111827; */
}

.USER_PROFILE_PICTURE_BUTTON {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  margin-top: 2em;
}

.USER_PROFILE_SAVE_BUTTON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-right: 1em;
}

.USER_PROFILE_CONTACT_DETAILS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  max-width: 1200px;
  min-width: 700px;
  height: 300px;
  min-height: 300px;
  margin-top: 50px;
  background-color: #111827;
  border-radius: 20px;
}

.USER_PROFILE_CONTACT_DETAILS_HEADER_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  min-width: 90%;
  min-height: 50px;
}

.USER_PROFILE_CONTACT_DETAILS_HEADER_CONTAINER h1 {
  font-family: sans-serif;
  font-size: 1.2rem;
  color: #dddddd;
  margin-right: auto;
}
.USER_PROFILE_CONTACT_DETAILS_HEADER_CONTAINER span {
  font-family: sans-serif;
  font-size: 1.2rem;
  color: #dddddd;
  margin-left: auto;
}

.USER_PROFILE_CONTACT_DETAILS_BODY_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  min-width: 90%;
  min-height: 200px;
}

.USER_PROFILE_INPUT_GROUP_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 45%;
  min-width: 350px;
  min-height: 200px;
  /* background-color: #dddddd; */
}

.USER_PROFILE_INPUT_FIELD_CONTAINER {
  width: 100%;
}

.USER_PROFILE_INPUT_FIELD {
  color: #dddddd !important;
  border-radius: 10px !important;
  border: 0.01em solid #305360 !important;
}

.USER_PROFILE_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #111827 inset !important;
  -webkit-text-fill-color: #a2aaad;
}

.USER_PROFILE_INPUT_FIELD_LABEL {
  color: #719fb0 !important;
  z-index: 10 !important;
  background-color: #111827;
  padding-right: 10px !important;
  padding-left: 2px !important;
}

.USER_PROFILE_PERMISSIONS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  max-width: 1200px;
  min-width: 700px;
  height: 150px;
  min-height: 150px;
  margin-top: 50px;
  background-color: #111827;
  border-radius: 20px;
  margin-bottom: 100px;
}

.USER_PROFILE_PERMISSION_BODY_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 2em;
}
