.JOB_TASK_ITEM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  max-width: 1100px;
  min-height: max-content;
  height: max-content;
  border-bottom: 1px solid var(--border-color);
  margin-top: 20px;
}

.JOB_TASK_ITEM_HEADER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  color: var(--header-color);
}

.JOB_TASK_ITEM_HEADER > h4 {
  font-family: Roboto;
  /* text-decoration: underline; */
}

.JOB_TASK_ITEM_HEADER > span {
  font-family: Roboto;
  margin-left: 1em;
  text-decoration: underline;
}

.JOB_TASK_ITEM_FEATURES_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-height: 100px;
  width: 100%;
  margin-top: 5px;
}
.JOB_TASK_ITEM_FEATURES_CONTAINER p {
  color: var(--secondary-font-color);
  font-family: Roboto;
}
.JOB_TASK_ITEM_FEATURES_CONTAINER span {
  color: var(--field-label);
  font-size: 0.8rem;
  font-family: sans-serif;
}
.JOB_TASK_ITEM_NUMBER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  padding: 10px;
  min-height: 90px;
  min-width: 80px;
  background-color: var(--search-item-bg);
}
.JOB_TASK_ITEM_ASSIGNED_BY {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  min-height: 90px;
  min-width: 80px;
  background-color: var(--search-item-bg);
}
.JOB_TASK_ITEM_ASSIGNED_TO {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  min-height: 90px;
  min-width: 80px;
  background-color: var(--search-item-bg);
}
.JOB_TASK_ITEM_STATUS {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  min-height: 90px;
  min-width: 80px;
}
.JOB_TASK_ITEM_STATUS span {
  color: #2c3e50;
  font-weight: 600;
}
.JOB_TASK_ITEM_STATUS p {
  color: #131313;
  font-weight: 600;
}
.JOB_TASK_ITEM_PRIORITY {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  min-height: 90px;
  min-width: 80px;
  background-color: var(--search-item-bg);
}
.JOB_TASK_ITEM_REPORT {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-height: 100px;
  width: 85%;
  min-width: 80px;
}
.JOB_TASK_ITEM_REPORT span {
  margin-right: auto;
  color: var(--field-label);
  font-family: sans-serif;
  font-weight: bold;
}
.JOB_TASK_ITEM_REPORT p {
  text-align: start;
  color: var(--secondary-font-color);
  font-family: Roboto;
}
