.DAMAGE_REPORT_JOB_CARD_IMAGES_MAIN_CONTAINER {
  display: flex;
  flex-direction: row;
  width: 900px;
  min-height: 320px;
  overflow: auto;
  transition: all 1s ease-in-out;
}

.DAMAGE_REPORT_JOB_CARD_IMAGES_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 350px;
  transition: all 1s ease-in-out;
}

.DAMAGE_REPORT_JOB_CARD_REPORT_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 350px;
  padding-block: 1em;
  margin-top: 20px;
  transition: all 1s ease-in-out;
}

.DAMAGE_REPORT_JOB_CARD_REPORT_CONTAINER {
  display: flex;
  width: 70%;
  height: 100%;
}
.DAMAGE_REPORT_FIELD_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 20px;
}

.DAMAGE_REPORT_ITEM_ACTIONS_MAIN_CONTAINER {
  display: flex;
  flex-direction: row;
}

.DAMAGE_REPORT_INPUT_FIELD {
  color: var(--secondary-font-color) !important;
  border-radius: 10px !important;
  /* height: 50px !important; */
  border: 0.01em solid var(--border-color) !important;
}
.DAMAGE_REPORT_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--input-section-background) inset !important;
  -webkit-text-fill-color: var(--secondary-font-color);
}

.DAMAGE_REPORT_INPUT_FIELD_LABEL {
  color: var(--field-label) !important;
  z-index: 10 !important;
  background-color: var(--input-section-background);
  padding-right: 10px !important;
  padding-left: 2px !important;
}
