.CLIENTS_ACCOUNTS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: max-content;
}

.CLIENTS_ACCOUNTS_FORM_HEADER_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
}

.CLIENTS_ACCOUNTS_FORM_HEADER_CONTAINER > h1 {
  font-family: sans-serif;
  font-size: 1.8rem;
  color: var(--header-color);
}

.CLIENTS_ACCOUNTS_FORM_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-width: 1000px;
  justify-content: space-around;
  transition: all 1s ease-in-out;
}

.CLIENTS_ACCOUNTS_TABLE_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  /* padding: 10px; */
  width: 95%;
  min-height: 400px;
  max-height: 600px;
  min-width: 900px;
  margin-top: 50px;
  margin-bottom: 100px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.CLIENTS_ACCOUNTS_FORM_INPUT_GROUP {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 230px;
  min-width: 200px;
  margin: 2em;
}

.CLIENTS_ACCOUNTS_FORM_INPUT_FIELD_CONTAINER {
  margin: 1em;
  width: 100%;
}
.CLIENTS_ACCOUNTS_INPUT_FIELD {
  color: var(--font-color) !important;
  border-radius: 10px !important;
  /* height: 50px !important; */
  border: 0.01em solid var(--border-color) !important;
}

.CLIENTS_ACCOUNTS_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--background) inset !important;
  -webkit-text-fill-color: var(--font-color);
}

.CLIENTS_ACCOUNTS_INPUT_FIELD_LABEL {
  color: var(--field-label) !important;
  z-index: 10 !important;
  background-color: var(--background);
  padding-right: 10px !important;
  padding-left: 2px !important;
}
