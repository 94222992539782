.JOB_CARD_ITEM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  min-height: max-content;
  height: max-content;
  /* border: 1px solid #2c3e50; */
  background-color: var(--search-item-bg);
  border-radius: 20px;
  margin-top: 20px;
}

.JOB_CARD_ITEM_CONTAINER span {
  color: var(--field-label);
  font-size: 0.8rem;
  font-family: sans-serif;
}
.JOB_CARD_ITEM_CONTAINER p {
  color: var(--secondary-font-color);
  font-family: Roboto;
  font-size: 1rem;
  text-align: center;
}

.JOB_CARD_ITEM_CARD_DETAILS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: max-content;
  margin-bottom: 10px;
  overflow: auto;
}
.JOB_CARD_ITEM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  max-width: 200px;
  min-width: 120px;
  min-height: 100px;
  height: max-content;
  padding: 5px;
  padding-top: 20px;
  overflow: auto;
}
.JOB_CARD_ITEM span {
  min-width: 100%;
  text-align: center;
}
.JOB_CARD_ITEM_DEVICE_DETAILS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  height: max-content;
  margin-bottom: 10px;
  overflow: auto;
}
.JOB_CARD_DEVICE_ITEM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  max-width: 200px;
  min-width: 100px;
  min-height: 100px;
  height: max-content;
  padding: 5px;
  padding-top: 20px;
  overflow: auto;
}
.JOB_CARD_ITEM_CLIENT_DETAILS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: max-content;
  overflow: auto;
}
.JOB_CARD_CLIENT_ITEM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  max-width: 200px;
  min-width: 120px;
  min-height: 100px;
  height: max-content;
  padding: 5px;
  padding-top: 20px;
  overflow: auto;
}
.JOB_CARD_CLIENT_ITEM span {
  min-width: 100%;
  text-align: center;
}
.JOB_CARD_ITEM_INSURANCE_DETAILS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: max-content;
  overflow: auto;
}
.JOB_CARD_INSURANCE_ITEM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  max-width: 200px;
  min-width: 100px;
  min-height: 100px;
  height: max-content;
  padding: 5px;
  padding-top: 20px;
  overflow: auto;
}

.JOB_CARD_ITEM_TASK_DETAILS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: max-content;
  overflow: auto;
}
.JOB_CARD_TASK_ITEM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  max-width: 200px;
  min-width: 100px;
  min-height: 100px;
  height: max-content;
  padding: 5px;
  padding-top: 20px;
  overflow: auto;
}

.JOB_CARD_ITEM_IMAGES_CONTAINER {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 90%;
  padding: 1em;
  overflow: auto;
}
