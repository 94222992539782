.COMPANY_REGISTR_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.STEP_FORM {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.FIRST_STEP_INPUT_FIELD {
  display: flex;
  padding: 10px;
  width: 200px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 0.1rem solid rgb(180, 179, 179);
}
.LAST_STEP_CONTAINER {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 250px;
}

input,
textarea,
select {
  outline: none;
}

.REGISTER_COMPANY_FORMS_CONTAINER {
  display: flex;
  width: 100%;
  min-height: 430px;
  height: max-content;
}
.REGISTER_COMPANY_DETAILS_CONTAINER {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  /* background-color: #eeeeee; */
  border-radius: 20px;
  padding: 10px;
}

.REGISTER_COMPANY_DETAILS_CONTAINER > span {
  color: rgb(224, 113, 113);
  font-family: Roboto;
}
.REGISTER_BRANCH_DETAILS_CONTAINER {
  display: flex;
  width: 100%;
  height: 400px;
  /* background-color: #eeeeee; */
  border-radius: 20px;
  padding: 10px;
}
.REGISTER_COMPANY_EMPLOYEE_CONTAINER {
  display: flex;
  width: 100%;
  height: 400px;
  /* background-color: #eeeeee; */
  border-radius: 20px;
  padding: 10px;
}
