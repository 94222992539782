.OTHERS_JOB_COMMENT_MAIN_CONTAINER {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.OTHERS_JOB_COMMENT_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  width: 80%;
  max-width: 400px;
  height: 120px;
  background-color: #506f8f;
  border-radius: 25px;
  margin-top: 10px;
}
.OTHERS_JOB_COMMENT_HEADER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: inherit;
}
.OTHERS_JOB_COMMENT_HEADER span {
  color: #eeeeee;
}
.OTHERS_JOB_COMMENT_BODY {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.OTHERS_JOB_COMMENT_BODY p {
  color: #ffffff;
}
.OTHERS_JOB_COMMENT_FOOTER {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.OTHERS_JOB_COMMENT_FOOTER span {
  color: #e1d89f;
}
