.NUMERIC_DATA_CONTAINER {
  display: grid;
  grid-template-columns: repeat(5, 200px);
  gap: 2em 2em;
  margin-top: 3em;
  margin-bottom: 3em;
  width: 90%;
  height: max-content;
  min-height: 200px;
  margin-top: 2em;
}
.NUMERICS_HEADR {
  font-family: sans-serif;
  color: var(--font-color);
  border-bottom: 1px solid var(--header-color);
}
.NUMERIC_DATA_BLOCK {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  background-color: var(--background1);
  min-height: 150px;
}

.NUMERIC_DATA_BLOCK > h2 {
  font-family: Roboto;
  color: var(--font-color);
  font-size: 3rem;
  line-height: 30px;
}

.NUMERIC_DATA_BLOCK > span {
  font-family: sans-serif;
  color: var(--header-color);
  font-size: 1.2rem;
  line-height: 30px;
}
