.STATEMENT_PERIOD_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 150px;
  margin-right: 2em;
  height: 98%;
  background-color: var(--background1);
  border-radius: 15px;
}

.STATEMENT_PERIOD_CONTAINER > span {
  font-size: 1.2rem;
  font-family: sans-serif;
  font-weight: bold;
  color: #f39422;
}

.STATEMENT_PERIOD_CONTAINER > h2 {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: var(--secondary-font-color);
}
