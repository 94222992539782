.DASHBOARD_WORKSAPCE_SUB_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: max-content;
  min-height: max-content;
}

.DETAILS_WRAPPER {
  display: grid;
  grid-template-columns: 45% 45%;
  gap: 1em 4em;
  margin-top: 1em;
  width: 100%;
}

.DETAILS_CONTAINER {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 3em 3em;
  margin-top: 3em;
  padding: 1em;
  padding-block: 2em;
  background-color: var(--background1);
  border-radius: 10px;
  width: 100%;
}

.ONE_FIELD_DETAILS_CONTAINER {
  margin-top: 3em;
  padding: 1em;
  padding-block: 2em;
  background-color: var(--background1);
  border-radius: 10px;
  width: 100%;
}

.DETAIL_FIELD {
  color: var(--font-color) !important;
  border-bottom: 1px solid var(--field-label);
}

.DETAIL_FIELD input:-webkit-autofill {
  -webkit-text-fill-color: var(--font-color);
}

.DETAIL_FIELD_LABEL {
  color: var(--field-label) !important;
}
