.JOB_TASK_TICKET_MAIN_CONTAINER {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 110px;
  min-height: 110px;
  border-radius: 20px;
  background-color: var(--ticket-background);
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 10px;
}
.JOB_TASK_TICKET_CONTAINER {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  height: 110px;
  min-height: 110px;
  z-index: 1;
}
.JOB_TASK_TICKET_HEADER {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 95%;
  height: 30px;
  padding: 5px;
}
.JOB_TASK_TICKET_HEADER span {
  margin-right: 10px;
  color: #eeeeee;
  font-family: monospace;
}
.JOB_TASK_TICKET_BODY {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 60%;
  height: 30px;
  padding: 5px;
}
.JOB_TASK_TICKET_FOOTER {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 95%;
  height: 30px;
  padding: 10px;
}
.JOB_TASK_TICKET_FOOTER span {
  margin-right: 10px;
  color: #eeeeee;
  font-family: monospace;
}

.JOB_TASK_TICKET_PRIORITY {
  position: absolute;
  right: 0px;
  width: 20px;
  height: 100%;
  background-color: #ffdd93;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 6;
  transition: all 0.5s ease-in-out;
}
.JOB_TASK_TICKET_PRIORITY .JOB_TASK_PRIORITY {
  display: none;
}
.JOB_TASK_TICKET_PRIORITY:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.JOB_TASK_PRIORITY {
  padding: 5px;
}
.JOB_TASK_PRIORITY > span {
  font-size: 0.9rem;
  font-weight: bold;
  color: #082032;
}
.JOB_TASK_PRIORITY > p {
  font-family: Arial;
  font-size: 1.8rem;
  font-weight: bolder;
  height: 20px;
  color: #0f2231;
}

.JOB_TASK_TICKET_PRIORITY:hover .JOB_TASK_PRIORITY {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #010101;
  font-size: 1.5rem;
}

.JOB_TASK_TICKET_OVERDUE_INDICATOR {
  position: absolute;
  left: 0px;
  width: 20px;
  height: 100%;
  background-color: #f06c67;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: all 0.3s ease-in-out;
  animation: overdue 1.5s infinite;
  z-index: 0;
}

.JOB_TASK_TICKET_OVERDUE_INDICATOR_LYER {
  position: absolute;
  left: 2px;
  width: 35px;
  height: 100%;
  background-color: var(--ticket-background);
  opacity: 1;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: all 0.2s ease-in-out;
  z-index: 5;
}
.JOB_TASK_TICKET_OVERDUE_INDICATOR_LYER
  .JOB_TASK_TICKET_OVERDUE_INDICATOR_CONTENT {
  display: none;
}

.JOB_TASK_TICKET_OVERDUE_INDICATOR_LYER:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f06c67;
  width: 99%;
  opacity: 0.95;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.JOB_TASK_TICKET_OVERDUE_INDICATOR_CONTENT > span {
  font-size: 1rem;
  font-weight: bold;
  height: 15px;
  color: #0f2231;
}
.JOB_TASK_TICKET_OVERDUE_INDICATOR_CONTENT > p {
  font-family: Arial;
  font-size: 0.9rem;
  font-weight: bolder;
  height: 10px;
  color: #0f2231;
}
.JOB_TASK_TICKET_OVERDUE_INDICATOR_CONTENT > h6 {
  font-family: Arial;
  font-size: 0.75rem;
  font-weight: bolder;
  height: 10px;
  color: #d2f0fd;
  font-family: monospace;
}

.JOB_TASK_TICKET_OVERDUE_INDICATOR_LYER:hover
  .JOB_TASK_TICKET_OVERDUE_INDICATOR_CONTENT {
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 5px;
  transition: all 2s ease-in-out;
  animation: attention 1.5s forwards;
}

@keyframes overdue {
  0% {
    box-shadow: 1px 1px 15px #ee453f;
    background-color: none;
    left: 1px;
    border-left: 1px solid #ee453f;
  }
  50% {
    box-shadow: 1px 1px 15px #082032;
    background-color: #082032;
    border-left: 1px solid #722e2c;
  }
  100% {
    box-shadow: 1px 1px 15px #ee453f;
    background-color: none;
    left: 1px;
    border-left: 1px solid #ee453f;
  }
}

@keyframes attention {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
