.JOB_COMMENT_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  width: 80%;
  max-width: 400px;
  min-height: 120px;
  background-color: #34495e;
  border-radius: 25px;
  margin-top: 10px;
}
.JOB_COMMENT_HEADER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}
.JOB_COMMENT_HEADER span {
  color: #eeeeee;
}
.JOB_COMMENT_ACTION_CONTAINER {
  display: flex;
  flex-direction: row;
}
.JOB_COMMENT_ACTION_DELETE {
  width: 40px;
  color: #e63e6d;
  margin-left: 10px;
}
.JOB_COMMENT_ACTION_DELETE:hover {
  cursor: pointer;
}
.JOB_COMMENT_ACTION_EDIT {
  width: 40px;
  color: #8bacf0;
}
.JOB_COMMENT_ACTION_EDIT:hover {
  cursor: pointer;
}
.JOB_COMMENT_ACTION_SAVE {
  width: 40px;
  color: #4e9f3d;
}
.JOB_COMMENT_ACTION_SAVE:hover {
  cursor: pointer;
}
.JOB_COMMENT_ACTION_CANCEL_EDIT {
  width: 40px;
  color: #fec260;
}
.JOB_COMMENT_ACTION_CANCEL_EDIT:hover {
  cursor: pointer;
}
.JOB_COMMENT_BODY {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.JOB_COMMENT_BODY p {
  color: #b9d2d2;
}
.JOB_COMMENT_FOOTER {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.JOB_COMMENT_FOOTER span {
  color: #e1d89f;
}
.TECHNICIAN_EDIT_COMMENT_INPUT_FIELD_CONTAINER {
  display: flex;
  width: 90%;
  padding-top: 10px;
}
