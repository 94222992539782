.JOB_CARD_TICKET_CONTAINER {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 110px;
  width: 300px;
  height: 110px;
  border-radius: 20px;
  background-color: var(--ticket-background);
  opacity: 1;
  margin-bottom: 15px;
}
.JOB_CARD_TICKET_HEADER {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 95%;
  height: 30px;
  padding: 5px;
  /* background-color: #075baf; */
}
.JOB_CARD_TICKET_HEADER span {
  margin-right: 10px;
  color: #eeeeee;
  font-family: monospace;
}
.JOB_CARD_TICKET_BODY {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
  height: 30px;
  padding: 5px;
  /* background-color: #4d7eaf; */
}
.JOB_CARD_TICKET_FOOTER {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 95%;
  height: 30px;
  padding: 10px;
  /* background-color: #6085aa; */
}
.JOB_CARD_TICKET_FOOTER span {
  margin-right: 10px;
  color: #eeeeee;
  font-family: monospace;
}
