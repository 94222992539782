.HISTOGRAM_CHART_MAIN_CONTAINER {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid #2c3e50;
  padding: 5px;
  border-radius: 20px;
  /* box-shadow: 1px 1px 10px #2c3e50; */
}

.HISTOGRAM_CHART_INFO_CONTAINER {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 5px;
  left: 5px;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  z-index: 100;
}
