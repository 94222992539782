.DASHBOARD_SPACE_HEADER_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.DASHBOARD_SPACE_HEADER > h1 {
  font-family: sans-serif;
  color: var(--header-color);
  height: 20px;
}

.DASHBOARD_SPACE_HEADER > span {
  color: var(--secondary-font-color);
  margin-left: 0.5em;
}
