.CAPTURE_PAYMENT_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: max-content;
}

.CAPTURE_PAYMENT_PROCESS_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 98%;
  height: 320px;
  margin-top: 1em;
  border-radius: 20px;
  margin-bottom: 1em;

  /* background-color: #111827; */
}

.CAPTURE_PAYMENT_PROCESS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 48%;
  height: 320px;
  margin-top: 1em;
  border-radius: 20px;
  margin-right: auto;
  background-color: var(--background1);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.CAPTURE_PAYMENT_PROCESS_CAPTURE_HEADER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: 90%;
  color: var(--header-color);
}

.CAPTURE_PAYMENT_PROCESS_ALLOCATE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 48%;
  height: 320px;
  margin-top: 1em;
  border-radius: 20px;
  margin-left: auto;
  background-color: var(--background1);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.CAPTURE_PAYMENT_PROCESS_DATA {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  width: 95%;
}
.CAPTURE_PAYMENT_PROCESS_DATA > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 200px;
  height: 90%;
  width: 230px;
  margin-left: 1em;
  /* background-color: #305360; */
}
.CAPTURE_PAYMENT_PROCESS_DATA > div > div {
  margin-bottom: 1em;
  width: 230px;
}
.CAPTURE_PAYMENT_NOTE_FIELD {
  color: var(--font-color) !important;
  border-radius: 10px !important;
  border: 0.01em solid var(--border-color) !important;
}

.CAPTURE_PAYMENT_NOTE_FIELD_LABEL {
  color: var(--field-label) !important;
  z-index: 10 !important;
  background-color: var(--background1);

  padding-right: 10px !important;
  padding-left: 2px !important;
}

.CAPTURE_PAYMENT_PROCESS_BUTTON {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 90%;
}

.CAPTURE_PAYMENT_PROCESS_ALLOCATE_HEADER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: 100%;
  color: var(--header-color);
}
.CAPTURE_PAYMENT_PROCESS_ALLOCATE_HEADER > span {
  margin-left: 2em;
}
.CAPTURE_PAYMENT_PROCESS_ALLOCATE_BODY {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1em;
  min-width: 98%;
  width: max-content;
  height: 80%;
  min-height: 200px;
  /* background-color: #305360; */
}

.CAPTURED_PAYMENT_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: max-content;
  border-radius: 5px;
  margin-bottom: 1em;
}

.CAPTURED_PAYMENT_DATA_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  background-color: var(--background1);
  border-radius: 5px;
  margin-bottom: 1em;
}
.CAPTURED_PAYMENT_DATA_CONTAINER > div {
  display: flex;
  flex-direction: column;
  color: var(--secondary-font-color);
  margin: 2px;
}
.CAPTURED_PAYMENT_DATA_CONTAINER > div > span {
  color: var(--font-color);
}

.CAPTURED_PAYMENT_ALOCATE_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100px;
  width: 100%;
  /* background-color: #1a253b; */
  border-radius: 5px;
  margin-bottom: 1em;
}
