.JOB_TASK_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 99%;
  width: 99%;
  /* min-height: 99%;  */
  min-height: 900px;
  padding-top: 15px;
  transition: all 1s ease-in-out;
}

.JOB_TASK_HOT_ACTIONS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  min-height: 60px;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 20px;
}
.JOB_TASK_DEVICE_INFO_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 150px;
  height: 200px;
  border-radius: 20px;
  padding: 5px;
  margin-bottom: 20px;
}

.JOB_TASK_IMAGES_CONTAINER {
  display: flex;
  /* align-items: center; */
  width: 100%;
  min-height: 350px;
  /* border: 1px solid #2c3e50; */
  border-radius: 20px;
  padding: 5px;
  padding-top: 20px;
}

.JOB_TASK_REPORTS_COMMENTS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 900px;
  min-width: 100%;
  margin-bottom: 50px;
}
