.CLIENT_FORM_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: max-content;
  width: 100%;
  transition: all 1s ease-in-out;
}

.CLIENT_INSURANCE_SWITCH_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 400px;
  height: 50px;
  color: #dddddd;
  background-color: #064663;
  border-radius: 25px;
}

.ADD_CLIENT_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  transition: all 1s ease-in-out;
}

.ADD_CLIENT_FORM_CLIENT {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-width: 1000px;
  justify-content: space-around;
  transition: all 1s ease-in-out;
}

.ADD_CLIENT_FORM_INPUT_GROUP {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 230px;
  min-width: 200px;
  margin: 2em;
  transition: all 1s ease-in-out;
}

.ADD_CLIENT_FORM_INPUT_FIELD_CONTAINER {
  margin-top: 1em;
  width: 100%;
}
.ADD_CLIENT_INPUT_FIELD {
  color: var(--font-color) !important;
  /* height: 50px !important; */
  border-bottom: 0.01em solid #305360 !important;
}

.ADD_CLIENT_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--background) inset !important;
  -webkit-text-fill-color: var(--font-color);
}

.ADD_CLIENT_INPUT_FIELD_LABEL {
  color: var(--field-label) !important;
  z-index: 10 !important;
}

.ADD_CLIENT_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  min-height: 80px;
  width: 100px;
  min-width: 80px;
  margin: 0.5em;
  border-radius: 50px;
  background-color: #064663;
  box-shadow: 1px 1px 10px #041c32;
  transition: all 0.5s ease-in-out;
}

.ADD_CLIENT_BUTTON > div {
  display: flex;
  align-self: center;
  font-family: Arial;
  font-size: 1.1rem;
  color: #a5afc2;
}

.ADD_CLIENT_BUTTON:hover {
  cursor: pointer;
  background-color: #06384f;
}

.SAVED_CLIENT_CONTAINER {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #2c3e50;
  border-radius: 10px;
  padding: 10px;
  width: 95%;
  min-height: 400px;
  max-height: 500px;
  margin-top: 50px;
  margin-bottom: 10px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.SAVED_CLIENT_ITEM_CONTAINER {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #2c3e50;
  /* border-radius: 10px; */
  height: 60px;
  min-height: 60px;
  width: 95%;
  margin-top: 10px;
}

.SAVED_CLIENT_ITEM {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  column-gap: 3rem;
  padding-left: 10px;
  align-items: center;
  justify-content: space-around;
  width: 95%;
  height: 60px;
}

.CLIENT_TABLE_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  /* padding: 10px; */
  width: 95%;
  min-height: 400px;
  max-height: 600px;
  min-width: 900px;
  margin-top: 50px;
  margin-bottom: 100px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
