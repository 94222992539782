.FORGOT_PASSWORD_PAGE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 150px;
  height: 100vh;
}
.FORGOT_PASSWORD_FORM {
  display: flex;
  flex-direction: column;
  width: 400px;
}
.FORGOT_PASSWORD_LOADING_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  font-size: 6rem;
  color: #27496d;
}
.FORGOT_PASSWORD_INPUT_FIELD {
  color: #27496d !important;
}
.FORGOT_PASSWORD_ERROR_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
}
.FORGOT_PASSWORD_ERROR_CONTAINER p {
  font-size: 1rem;
  font-family: Roboto;
  font-weight: 600;
  color: #f85f73;
}
.FORGOT_PASSWORD_FORM_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.FORGOT_PASSWORD_FORM_BUTTON h6 {
  color: #27496d;
  font-family: Roboto;
  font-size: 1.3rem;
  font-weight: 700;
}
.FORGOT_PASSWORD_FORM_BUTTON h6:hover {
  cursor: pointer;
}
