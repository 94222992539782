.LOGIN_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  height: max-content;
  width: 100%;
  padding-bottom: 100px;
  background-color: #0b0f19;
}

.LOGIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #0e3654;
  padding-top: 2em;
  padding-bottom: 5em;
  border-radius: 2em;
  box-shadow: 0px 0px 20px #000;
}
.LOGIN_FORM_ICON {
  color: #1976d2;
  font-size: 8rem;
}
.form-signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.LOGIN_INPUT_FIELD {
  color: #dae1e7 !important;
  border-radius: 10px !important;
  /* height: 50px; */
  border: 1px solid #1976d2 !important;
}
.LOGIN_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #0e3654 inset !important;
  -webkit-text-fill-color: #dae1e7;
}
.LOGIN_INPUT_FIELD_LABEL {
  color: #b8b8b8 !important;
  font-family: monospace !important;
  font-size: 0.85rem !important;
  background-color: #0e3654 !important;
  width: 90px;
  padding-left: 2em !important;
}
.LOGIN_BUTTON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px !important;
  height: 40px;
  width: 150px !important;
  margin-bottom: 10px;
  /* box-shadow: 1px 1px 10px #788592; */
}
.LOGIN_BUTTON_CONTAINER h6 {
  font-size: 1rem;
  font-family: Roboto;
  color: #dae1e7;
}
.LOGIN_BUTTON_CONTAINER:hover {
  cursor: pointer;
}

.FORGOT_PASSWORD_CONTAINER h6 {
  color: rgb(235, 114, 114) !important;
}

.FORGOT_PASSWORD_CONTAINER h6 {
  font-family: monospace;
  font-size: 1.2rem;
  font-weight: 500;
  color: #dae1e7;
}
.FORGOT_PASSWORD_CONTAINER h6:hover {
  cursor: pointer;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .form-signin {
    width: 80vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .form-signin {
    width: 80vw;
  }

  .login_container {
    align-content: center !important;
    justify-content: center !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .login_container {
    align-content: center !important;
    justify-content: center !important;
  }
  .form-signin {
    width: 500px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .form-signin {
    width: 500px;
  }

  .login_container {
    float: right;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .form-signin {
    width: 500px;
  }
  .login_container {
    float: right;
  }
}
