.DASHBOARD_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: var(--background);
  padding-top: 50px;
  padding-left: 2em;
  padding-right: 2em;
}
.DASHBOARD_HEADER_MAIN_CONTAINER {
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: flex-start;
  min-height: 80px;
  max-height: 200px;
  width: 100%;
  border-bottom: 1px solid var(--header-color);
  color: var(--font-color);
  margin: 1em;
}
.DASHBOARD_BODY_MAIN_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 80vh;
  width: 100%;
  border-radius: 15px;
}
.DASHBOARD_LEFT_NAVIGATION_BAR_MAIN_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  min-width: max-content;
  width: 10%;
  border-right: 1px solid #2c3e50;
}
.DASHBOARD_WORK_SPACE_MAIN_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 85%;
  margin-left: 1em;
  overflow: auto;
}
