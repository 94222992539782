.RECEPTION_MAIN_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  min-width: max-content;
  /* padding-top: 100px; */
  background-color: var(--background);
}
.RECEPTION_LEFT_SIDE_BAR_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 80vh;
  width: 10vw;
  min-width: 120px;
  padding-top: 20px;
  margin-left: 20px;
  border-radius: 20px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.RECEPTION_RIGHT_SIDE_BAR_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 80vh;
  width: 20vw;
  min-width: 350px;
  padding-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 20px;
}
.RECEPTION_JOB_CARD_SEARCH_SIDE_BAR_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 25px;
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  margin-bottom: 20px;
}
.JOB_CARDS_SIDE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 45%;
  height: 45%;
  width: 95%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.RECEPTION_JOB_TASKS_SEARCH_BAR_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 25px;
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.JOB_TASKS_SIDE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 45%;
  width: 95%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.RECEPTION_WORKSPACE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 80vh;
  height: 80vh;
  width: 60vw;
  min-width: 1200px;
  padding-top: 20px;
  margin-left: 20px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
}
.RECEPTION_FORMS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 98%;
  width: 98%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
*::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--background);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px var(--background);
  background-color: var(--background1);
}

.RECEPTION_ACTION_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: max-content;
  min-width: 120px;
}

.ADD_CLIENT {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  height: 100px;
  width: 100px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  margin-bottom: 10px;
  transition: all 0.5s ease-in-out;
}
.ADD_CLIENT:hover {
  cursor: pointer;
  background-color: #1f2c39;
}
.ADD_CLIENT span {
  color: #6b778d;
}
.RECEPTION_NAVIGATION_BUTTON_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  height: 100px;
  width: 100px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  margin-bottom: 10px;
  transition: all 0.5s ease-in-out;
}
.RECEPTION_NAVIGATION_BUTTON_CONTAINER:hover {
  cursor: pointer;
  background-color: var(--background1);
}
.RECEPTION_NAVIGATION_BUTTON_CONTAINER span {
  color: var(--secondary-font-color);
}

.BOOK_DEVICE_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 40vh;
  min-width: 60vw;
}
.JOB_TASK_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 40vh;
  min-width: 60vw;
}

.JOB_CARD_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  height: 70vh;
  min-width: 61vw;
}
.ADD_CLIENT_FORM_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 70vh;
  min-width: 63vw;
}
.COLLECT_DEVICE_FORM_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  min-width: 63vw;
}
.CREATE_INVOICE_FORM_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  min-width: 63vw;
}
.CREATE_DAMAGE_REPORT_FORM_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  min-width: 63vw;
}

.ASSIGN_TASK_MODAL {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  min-height: 400px;
  background-color: var(--input-section-background);
  opacity: 0.95;
  border-radius: 20px;
  padding: 20px;
}
.ASSIGN_TASK_MODAL h2 {
  font-family: Arial;
  color: var(--secondary-font-color);
}

.SUBMIT_TASK_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 25px;
  background-color: var(--background2);
}
.SUBMIT_TASK_BUTTON:hover {
  cursor: pointer;
  background-color: var(--background1);
}
.SUBMIT_TASK_BUTTON span {
  color: var(--secondary-font-color);
  font-family: Arial;
}

.JOB_CARD_INPUT_FIELD {
  color: var(--secondary-font-color) !important;
  border-radius: 10px !important;
}
.JOB_CARD_INPUT_FIELD_LABEL {
  color: var(--field-label) !important;
  font-family: monospace !important;
  font-size: 0.85rem !important;
}

.JOB_SEARCH_INPUT_FIELD {
  color: var(--secondary-font-color) !important;
  border: none !important;
}
