.NAV_TOOLBAR_CONTAINER {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
}
.NAVBAR_LEFT_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 200px;
  height: 50px;
}
.NAVBAR_DRAWER_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 2.4rem;
  color: #dae1e7;
}
.NAVBAR_DRAWER_BUTTON:hover {
  color: aquamarine;
  cursor: pointer;
}

.NAVBAR_BRAND_NAME {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NAVBAR_BRAND_NAME img {
  width: 50px;
  height: 50px;
  margin-right: 0.5em;
}

.NAVBAR_BRAND_NAME h6 {
  font-family: monospace;
  font-size: 2rem;
  font-weight: 800;
  color: #dddddd;
}
.NAVBAR_BRAND_NAME span {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 500;
  color: #a4c8e3;
  margin-left: 10px;
  margin-top: 1em;
}
.NAVBAR_BRAND_NAME h6:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: scale(1.1);
}
.NAVBAR_BRAND_NAME h6:not(:hover) {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  transform: scale(1);
}
.NAVBAR_RIGHT_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border: 1px solid #fff; */
  height: 50px;
  /* width: 130px; */
}
.HEADER_USER_ACTIONS_CONTAINER {
  display: flex;
  flex-direction: row;
}
.NAVBAR_LOGIN_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 60px;
  padding-top: 5px;
  border-radius: 5px;
}
.NAVBAR_LOGIN_BUTTON h6 {
  font-family: Roboto;
  color: #bbbec0;
  font-size: 0.9rem;
}
.NAVBAR_LOGIN_BUTTON:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: scale(1.2);
}

.NAVBAR_LOGIN_BUTTON:not(:hover) {
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}

.NAVBAR_SIGNUP_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 60px;
  padding-top: 5px;
  border-radius: 5px;
}
.NAVBAR_SIGNUP_BUTTON:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: scale(1.2);
}

.NAVBAR_SIGNUP_BUTTON:not(:hover) {
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}
.NAVBAR_USER_NAME {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 60px;
  width: max-content;
  padding-top: 5px;
  border-radius: 5px;
}
.NAVBAR_USER_NAME:hover {
  cursor: pointer;
}
.NAVBAR_SIGNUP_BUTTON h6 {
  font-family: Roboto;
  color: #bbbec0;
  font-size: 0.9rem;
}
.NAVBAR_SIGNUP_BUTTON:hover {
  cursor: pointer;
}

.CAP_DRAWER_HEADER_CONTAINER {
  height: 120px;
  background-color: #101111;
}
.CAP_DRAWER_BODY_CONTAINER {
  width: 280px;
  height: 1000px;
  opacity: 1;
}

.HEADER_MENU_BUTTON {
  display: flex;
  outline: none !important;
}
*:focus:not(.focus-visible) {
  outline: none;
}
.HEADER_MENU_LIST {
  background-image: linear-gradient(60deg, #142850, #00909e);
  /* border: 1px solid #dae1e7; */
  border-radius: 10px !important;
}
.HEADER_MENU_PAPER {
  border-radius: 10px !important;
  top: 30px !important;
  opacity: 0.7 !important;
}
.HEADER_MENU_ITEM h6 {
  width: 150px;
  color: #dae1e7;
  font-family: monospace;
  font-size: 1rem;
}
@media only screen and (max-width: 600px) {
  .NAVBAR_RIGHT_CONTAINER {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .NAVBAR_RIGHT_CONTAINER {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .NAVBAR_RIGHT_CONTAINER {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .HEADER_MENU_BUTTON {
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  .HEADER_MENU_BUTTON {
    display: none;
  }
}
