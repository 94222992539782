.DAMAGE_REPORT_FORM_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  width: 98%;
  border-radius: 15px;
}

.DAMAGE_REPORT_FORM_INFO_CONTAINER {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 200px;
  border-radius: 15px;
  margin-bottom: 20px;
  transition: all 1s ease-in-out;
}

.DAMAGE_REPORT_INPUT_GROUP_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
  /* border: 1px solid #2c3e50; */
  border-radius: 15px;
  background-color: var(--input-section-background);
  padding: 0.5em;
}
