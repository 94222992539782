.JOB_CARD_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 99%;
  /* min-height: 99%;  */
  min-height: 900px;
  padding-top: 15px;
  transition: all 1s ease-in-out;
}
.JOB_CARD_FORM_S {
  display: flex;
  flex-direction: column;
  background-color: var(--input-section-background);
  border-radius: 15px;
  padding: 0.5em;
  margin: 0.5em;
}

.JOB_CARD_FIELDS_FORM_CONTAINER {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 30px;
  justify-content: center;
  min-height: max-content;
}

.JOB_CARD_FORM_CLIENT {
  display: flex;
  flex-direction: column;

  margin-top: 2em;
  height: max-content;
  background-color: var(--input-section-background);
  border-radius: 15px;
  padding: 0.5em;
  margin: 0.5em;
}
.JOB_CARD_FORM_INSURANCE {
  display: flex;
  flex-direction: column;
  gap: 0.07rem;
  height: max-content;
  background-color: var(--input-section-background);
  border-radius: 15px;
  padding: 0.5em;
  margin: 0.5em;
}
.JOB_CARD_FORM_DEVICE {
  display: flex;
  flex-direction: column;
  height: max-content;
  background-color: var(--input-section-background);
  border-radius: 15px;
  padding: 0.5em;
  margin: 0.5em;
}
.JOB_CARD_FORM_DESCRIPTION_CONTAINER {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: max-content;
  background-color: var(--input-section-background);
  border-radius: 15px;
  padding: 0.5em;
  margin: 0.5em;
  /* padding: 10px; */
}
.JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER {
  padding: 5px;
  margin-bottom: 5px;
}

.BOOK_DEVICE_IMAGE {
  display: flex;
  /* align-items: center; */
  min-width: 85%;
  max-width: 85%;
  min-height: 250px;
  border-radius: 20px;
  padding: 5px;
  padding-top: 20px;
}
.ADD_IMAGE_BUTTON {
  position: relative;
  overflow: hidden;
  display: inline-block;
  height: 100px;
  width: 100px;
  margin-left: 10px;
  border: 1px solid #2c3e50;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  font-size: 2.9rem;
  color: #6b778d;
}
.ADD_IMAGE_BUTTON:hover {
  cursor: pointer;
  border-radius: 50px;
}
.ADD_IMAGE_BUTTON input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.UPLOAD_FILE_ICON {
  display: flex;
  width: inherit;
  height: inherit;
  align-items: center;
  justify-content: center;
}
.JOB_CARD_UPLOADED_IMAGES_CONTAINER {
  display: grid;
  grid-auto-flow: column;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  grid-auto-rows: minmax(200px, auto);
  grid-row-gap: 100px;
  align-items: center;
  padding-left: 10px;
  width: 90%;
  min-height: 300px;
  max-height: 400px;
  border: 1px solid #2c3e50;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  transition: all 1s ease-in-out;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.JOB_CARD_UPLOADED_IMAGES_CONTAINER::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.JOB_CARD_UPLOADED_IMAGES_CONTAINER::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--background);
  border-radius: 10px;
}
.JOB_CARD_UPLOADED_IMAGES_CONTAINER::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--background);
  background-color: var(--border-color);
}
.JOB_CARD_UPLOADED_IMAGE_CONTAINER {
  display: flex;
  height: max-content;
  padding: 5px;
}
.BOOK_DEVICE_FORM_ACTION {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 50px;
  min-height: 80px;
  transition: all 1s ease-in-out;
}
.PROGRESS_CIRCLE_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.BOOK_DEVICE_FORM_ACTION_SAVE_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  width: 80px;
  height: 30px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  color: var(--secondary-font-color);
  font-family: Arial;
  font-weight: 400;
  transition: all 0.5s ease-in-out;
}
.BOOK_DEVICE_FORM_ACTION_SAVE_BUTTON:hover {
  cursor: pointer;
  background-color: var(--background1);
}

.BOOK_DEVICE_FORM_ACTION_CLEAR_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  color: var(--secondary-font-color);
  font-family: Arial;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}
.BOOK_DEVICE_FORM_ACTION_CLEAR_BUTTON:hover {
  cursor: pointer;
  background-color: rgba(252, 98, 144, 0.5);
}
/* .JOB_CARD_SELECT_CLIENT_MENU_CONTAINER {
  display: flex;
  position: relative;
}*/
.JOB_CARD_SELECT_CLIENT_MENU {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  opacity: 9;
  /* visibility: hidden; */
  position: absolute;
  /* left: 150px; */
  transform: translate(0, 10px);
  background-color: var(--background1);
  padding: 1rem;
  /* box-shadow: 1px 1px 10px var(--background2); */
  width: max-content;
  z-index: 50;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-radius: 10px;
}
.JOB_CARD_SELECT_CLIENT_MENU_CONTAINER {
  display: flex;
  flex-direction: column;
  min-width: 170px;
  max-width: 200px;
  max-height: 200px;
  min-height: 60px;
  overflow-wrap: break-word;
}
.CLIENTS_MENU_CLOSE_BUTTON {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 180px;
  height: 40px;
  min-height: 40px;
  color: #f05454;
}
.JOB_CARD_INPUT_FIELD {
  color: var(--secondary-font-color) !important;
  border-radius: 10px !important;
  /* height: 50px !important; */
  border: 0.01em solid var(--border-color) !important;
}

.JOB_CARD_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--input-section-background) inset !important;
  -webkit-text-fill-color: var(--secondary-font-color);
}

.JOB_CARD_INPUT_FIELD_LABEL {
  color: var(--secondary-font-color) !important;
  z-index: 10 !important;
  background-color: var(--input-section-background);
  padding-right: 10px !important;
  padding-left: 2px !important;
}

.MODAL_BODY {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 250px;
  background-color: var(--background1);
  /* border: 2px solid rgb(41, 40, 40); */
  box-shadow: 2px 2px 20px #1e272e;
  padding: 30px;
  border-radius: 20px;
  opacity: 0.95;
  transition: all 1s ease-in-out;
}
.UPLOAD_FILE_MODAL_HEADER {
  color: #e9ec17;
}
.DELETE_MODAL_ACTIONS {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 200px;
}
.UPLOAD_FILE_MODAL_BODY p {
  color: #b4b4b4;
  text-align: center;
  font-family: Roboto;
}
.UPLOAD_FILE_MODAL_ACTIONS {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 80px;
  height: 20px;
  border-radius: 20px;
  box-shadow: 1px 1px 10px #1e272e;
}
.UPLOAD_FILE_MODAL_ACTIONS h6 {
  color: #7a7a7a;
  font-family: Arial;
  /* margin-top: 5px; */
  height: 10px;
}
.UPLOAD_FILE_MODAL_ACTIONS:hover {
  cursor: pointer;
  color: #fdfbfb;
  transition: all 1s ease-in-out;
  transform: scale(1.1);
}
