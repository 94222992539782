.MEMBER_CARD_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 200px;
  height: max-content;
  width: 90%;
  max-width: 1000px;
  min-width: 1000px;
  background-color: var(--background1);
  border-radius: 10px;
  padding: 1em;
  margin-bottom: 2em;
}

.MEMBER_INFO_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
  width: 100%;
}

.MEMBER_INFO_CONTAINER > div {
  margin-inline: 1em;
}

.MEMBER_PERMISSIONS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: max-content;
}

.MEMBER_PERMISSIONS_HEADER {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-inline: 1em;
}

.MEMBER_PERMISSIONS_HEADER > h4 {
  color: var(--secondary-font-color);
  font-family: Roboto;
  margin-right: 2em;
  padding-top: 0.5em;
}
.MEMBER_PERMISSIONS_HEADER > span {
  color: var(--secondary-font-color);
  font-family: Roboto;
  margin-right: 2em;
  padding-top: 0.5em;
}

.MEMBER_PERMISSIONS {
  display: grid;
  grid-template-columns: repeat(6, auto);
  row-gap: 1em;
  align-items: flex-start;
  justify-content: space-around;
  color: #576cbc;
}

.PERMISSION_INPUT_FIELD {
  color: var(--font-color) !important;
  border-radius: 10px !important;
  /* height: 50px !important; */
  /* border: 0.01em solid var(--border-color) !important; */
}
.PERMISSION_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--background1) inset !important;
  -webkit-text-fill-color: var(--font-color);
}

.PERMISSION_INPUT_FIELD_LABEL {
  color: var(--field-label) !important;
  z-index: 10 !important;
  background-color: var(--background1);
  padding-right: 10px !important;
  padding-left: 2px !important;
}
