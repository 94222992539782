.COMPANY_EMPLOYEE_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  padding-top: 20px;
}
.COMPANY_EMPLOYEE_INPUT_FIELD_CONTAINER {
  margin-bottom: 20px;
}
.COMPANY_EMPLOYEE_INPUT_FIELD {
  width: 300px !important;
  font-weight: 500;
  color: #17293e !important;
}
.COMPANY_EMPLOYEE_INPUT_FIELD_LABEL {
  color: #182c43 !important;
}
