.WORKSPACE_JOB_REPORT_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  height: 50%;
  padding: 5px;
  background-color: var(--input-section-background);
  border-radius: 20px;
  margin-bottom: 20px;
}
.WORKSPACE_JOB_REPORT_LOADING {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 50%;
  padding: 5px;
}
.TECHNICIAN_REPORT_HEADER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 98%;
}
.JOB_REPORT_ACTION_CONTAINER {
  display: flex;
  flex-direction: row;
}
.JOB_REPORT_ACTION_DELETE {
  width: 40px;
  color: #e63e6d;
  margin-left: 10px;
}
.JOB_REPORT_ACTION_DELETE:hover {
  cursor: pointer;
}
.JOB_REPORT_ACTION_EDIT {
  width: 40px;
  color: #8bacf0;
}
.JOB_REPORT_ACTION_EDIT:hover {
  cursor: pointer;
}
.JOB_REPORT_ACTION_SAVE {
  width: 40px;
  color: #4e9f3d;
}
.JOB_REPORT_ACTION_SAVE:hover {
  cursor: pointer;
}
.JOB_REPORT_ACTION_CANCEL_EDIT {
  width: 40px;
  color: #fec260;
}
.JOB_REPORT_ACTION_CANCEL_EDIT:hover {
  cursor: pointer;
}
.JOB_REPORT_BODY {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
}

.JOB_REPORT_BODY p {
  color: var(--font-color);
}
.JOB_REPORT_FOOTER {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.TECHNICIAN_EDIT_REPORT_INPUT_FIELD_CONTAINER {
  display: flex;
  width: 100%;
  padding-top: 10px;
}

.TECHNICIAN_REPORT {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 98%;
}
.TECHNICIAN_REPORT span {
  color: var(--secondary-font-color);
}
.TECHNICIAN_ADD_REPORT_CONTAINER {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  width: inherit;
  margin-top: 1em;
}
.TECHNICIAN_ADD_REPORT_INPUT_FIELD_CONTAINER {
  display: flex;
  width: 80%;
  margin-top: 1em;
}
.JOB_TASK_REPORT_INPUT_FIELD {
  color: var(--font-color) !important;
  border-radius: 10px !important;
  border: 1px solid var(--border-color);
  margin-top: 1em;
}
.JOB_TASK_REPORT_INPUT_FIELD_LABEL {
  color: var(--field-label) !important;
  font-family: monospace !important;
  font-size: 0.85rem !important;
  background-color: var(--input-section-background);
  width: 140px;
  margin-top: 1em;
}
.TECHNICIAN_ADD_REPORT_POST_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 10px;
  border-radius: 40px;
  background-color: #34495e;
  transition: all 0.5s ease-in-out;
  color: aliceblue;
}
.TECHNICIAN_ADD_REPORT_POST_BUTTON:hover {
  cursor: pointer;
  box-shadow: 1px 1px 10px #2c3e50;
  transform: scale(1.1);
}

.TECHNICIAN_REPORT_FOOTER_CONTAINER {
  display: flex;
  width: 90%;
  align-items: flex-start;
  justify-content: flex-end;
}

.TECHNICIAN_REPORT_FOOTER_CONTAINER span {
  color: var(--secondary-font-color);
  font-size: 0.85rem;
}
