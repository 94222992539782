.ADD_MEMBERS_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  max-width: 1000px;
  min-width: 50%;
  margin-top: 1em;
  margin-left: 1em;
}

.MEMBER_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-block: 1em;
  padding: 1em;
  margin-top: 2em;
  height: max-content;
  width: 90%;
  min-width: 50%;
  overflow: auto;
}

.MEMBERS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-block: 1em;
  padding: 1em;
  margin-top: 3em;
  min-height: 600px;
  height: 700px;
  width: 90%;
  min-width: 50%;
  overflow: auto;
}
