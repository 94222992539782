.CHART_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2c3e50;
  padding: 5px;
  margin-top: 1em;
  border-radius: 10px;
  /* box-shadow: 1px 1px 10px #2c3e50; */
}
