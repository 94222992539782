.DATA_DRAWER_SLIDE {
  margin-top: 65px;
}

.DATA_DRAWER_JOB_INFO_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--background);
  height: 100%;
}

.DATA_DRAWER_JOB_INFO {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 400px;
  height: 40%;
  width: 100%;
  background-color: var(--background1);
  margin-top: 2em;
  overflow: auto;
}

.DATA_DRAWER_JOB_INFO_ITEM {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0.5em;
  border-bottom: 1px dashed var(--border-color);
}

.DATA_DRAWER_JOB_INFO_ITEM > span {
  font-family: Roboto;
  font-weight: bold;
  color: var(--field-label);
}

.DATA_DRAWER_JOB_INFO_ITEM > p {
  font-family: Arial;
  color: var(--font-color);
  margin-left: 1em;
}
