.TECNICIAN_WORKSPACE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 95%;
  width: 95%;
  /* min-height: 99%;  */
  min-height: 900px;
  padding-top: 15px;
  transition: all 1s ease-in-out;
}
.TECNICIAN_WORKSPACE_CONTAINER::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.TECNICIAN_WORKSPACE_CONTAINER::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--background);
  border-radius: 10px;
}
.TECNICIAN_WORKSPACE_CONTAINER::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--background);
  background-color: var(--border-color);
}
.WORKSPACE_HOT_ACTIONS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 20px;
}
.TASK_STATUS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
}
.TECH_DEVICE_INFO_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 150px;
  height: 200px;
  border-radius: 20px;
  /* border: 1px solid #2c3e50; */
  overflow: auto;
  padding: 5px;
  margin-bottom: 20px;
}
.TECH_DEVICE_DETAILS {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  min-height: 95%;
  border-radius: 20px;
  background-color: var(--background2);
}
.TECH_DEVICE_DETAILS h4 {
  text-align: center;
  color: var(--font-color);
  font-family: sans-serif;
  width: 90%;
}
.TECH_DEVICE_DETAILS span {
  font-size: 0.8rem;
  text-align: center;
  color: var(--secondary-font-color);
  font-family: Roboto;
}
.TECH_DEVICE_FAULT {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  min-height: 95%;
  border-radius: 20px;
  background-color: var(--background2);
}
.TECH_DEVICE_FAULT h4 {
  text-align: center;
  color: var(--font-color);
  font-family: sans-serif;
  width: 90%;
}
.TECH_DEVICE_FAULT span {
  font-size: 0.8rem;
  text-align: center;
  color: var(--secondary-font-color);
  font-family: Roboto;
}
.TECH_DEVICE_FAULT_DESCRIPTION {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  min-height: 95%;
  border-radius: 20px;
  background-color: var(--background2);
}
.TECH_DEVICE_FAULT_DESCRIPTION h4 {
  text-align: center;
  color: var(--font-color);
  font-family: sans-serif;
  width: 90%;
}
.TECH_DEVICE_FAULT_DESCRIPTION span {
  font-size: 0.8rem;
  text-align: center;
  color: var(--secondary-font-color);
  font-family: Roboto;
}

.WORKSPACE_JOB_REPORTS_COMMENTS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 900px;
  min-width: 100%;
  margin-bottom: 50px;
}
.TECHNICIAN_WORKSPACE_JOB_COMMENTS_CONTAINER {
  display: flex;
  flex-direction: column;
  height: max-content;
  min-height: 400px;
  min-width: 40%;
  background-color: var(--input-section-background);
  border-radius: 20px;
  overflow: auto;
  padding: 10px;
}
.TECHNICIAN_WORKSPACE_JOB_COMMENTS_CONTAINER::-webkit-scrollbar {
  width: 5px;
}
.TECHNICIAN_WORKSPACE_JOB_COMMENTS_CONTAINER::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--background2);
  border-radius: 10px;
}
.TECHNICIAN_WORKSPACE_JOB_COMMENTS_CONTAINER::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--background);
  background-color: var(--border-color);
}
.TECHNICIAN_ADD_COMMENT_CONTAINER {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  transition: all 0.5s ease-in-out;
  margin-top: auto;
}
.TECHNICIAN_ADD_COMMENT_INPUT_FIELD_CONTAINER {
  display: flex;
  width: 85%;
}

.JOB_TASK_COMMENT_INPUT_FIELD {
  color: var(--font-color) !important;
  border-radius: 10px !important;
  border: 1px solid #34495e;
}

.JOB_TASK_COMMENT_INPUT_FIELD_LABEL {
  color: var(--secondary-font-color) !important;
  font-family: monospace !important;
  font-size: 0.85rem !important;
  background-color: var(--input-section-background);
  width: 90px;
}

.TECHNICIAN_ADD_COMMENT_POST_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 10px;
  border-radius: 40px;
  background-color: #34495e;
  margin-top: 15px;
  color: #1597bb;
  transition: all 0.5s ease-in-out;
}
.TECHNICIAN_ADD_COMMENT_POST_BUTTON:hover {
  cursor: pointer;
  box-shadow: 1px 1px 10px #2c3e50;
  transform: scale(1.1);
}
.WORKSPACE_JOB_REPORTS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 880px;
  width: 50%;
  opacity: 0.9;
  border-radius: 20px;
  /* padding: 10px; */
}

.TASK_SELECT_STATUS_MENU {
  display: flex;
  flex-direction: column;
  /* position: relative; */
  justify-content: center;
  align-items: flex-start;
  opacity: 0.9;
  /* visibility: hidden; */
  position: absolute;
  /* left: 150px; */
  top: 160px;
  transform: translate(0, 10px);
  background-color: #2c3e50;
  /* padding: 1rem; */
  box-shadow: 1px 1px 10px #1e272e;
  min-width: 200px;
  width: max-content;
  z-index: 10;
  /* overflow: auto; */
  border-radius: 10px;
  min-height: 120px;
  height: max-content;
}
