.RECEPTION_JOB_CARD_SEARCH_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 99%;
  height: max-content;
}
.RECEPTION_JOB_CARD_SEARCH_FIELD_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  min-height: 48px;
  border-radius: 5px !important;
  border: 1px solid var(--border-color) !important;
  margin: 1em;
}

.RECEPTION_JOB_CARD_SEARCH_BUTTON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  min-height: 50px;
}
.RECEPTION_JOB_CARD_SEARCH_HEADER_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  min-height: 100px;
  margin-bottom: 2em;
  background-color: var(--background1);
  border-radius: 20px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.RECEPTION_JOB_CARD_SEARCH_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 900px;
  min-height: 50px;
  margin: 1em;
}
.RECEPTION_JOB_CARD_SEARCH_INPUT_FIELD {
  color: var(--font-color) !important;
  height: 50px !important;
  border: 0.01em solid var(--border-color) !important;
}

.RECEPTION_JOB_CARD_SEARCH_INPUT_FIELD_LABEL {
  color: var(--field-label) !important;
  z-index: 10 !important;
  background-color: var(--background1);
  padding-right: 10px !important;
  padding-left: 2px !important;
}

.RECEPTION_JOB_CARD_TEXT_SEARCH_INPUT_FIELD:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--background1) inset !important;
  -webkit-text-fill-color: var(--font-color);
}

.RECEPTION_JOB_CARD_SEARCH_ITEMS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: max-content;
  padding: 1px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
