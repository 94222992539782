.COMPAN_DETAILS_FORMS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  min-width: 1000px;
  padding: 2em;
}
.COMPANY_FORM_CONTAINER {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  margin-right: 5em;
  padding-top: 20px;
}
.COMPANY_INPUT_FIELD_CONTAINER {
  margin-bottom: 20px;
}
.COMPANY_INPUT_FIELD {
  width: 250px !important;
  min-width: 200px;
  font-weight: 500;
  color: #17293e !important;
}
.COMPANY_INPUT_FIELD_LABEL {
  color: #182c43 !important;
}
