.ADD_ITEM_FORM_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: max-content;
}

.ADD_ITEM_FORM_HEADER_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
}

.ADD_ITEM_FORM_HEADER_CONTAINER > h1 {
  font-family: sans-serif;
  font-size: 1.8rem;
  color: var(--header-color);
}

.ADD_ITEM_FORM_CONTAINER {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 45%);
  gap: 3em 2em;
  min-height: 400px;
  width: 100%;
  height: max-content;
  margin-top: 2em;
}

.ADD_ITEM_INPUT_GROUP_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 400px;
  border-radius: 20px;
  background-color: var(--background2);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.ADD_ITEM_INPUT_FIELD_CONTAINER {
  padding: 5px;
  margin-top: 1em;
  width: 90%;
}

.ADD_ITEM_INPUT_FIELD {
  color: var(--font-color) !important;
  border-radius: 10px !important;
  /* height: 50px !important; */
  border: 0.01em solid var(--border-color) !important;
}
.ADD_ITEM_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--background2) inset !important;
  -webkit-text-fill-color: var(--font-color);
}

.ADD_ITEM_INPUT_FIELD_LABEL {
  color: var(--field-label) !important;
  z-index: 10 !important;
  background-color: var(--background2);
  padding-right: 10px !important;
  padding-left: 2px !important;
}

.ADD_ITEM_ACTIONS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  height: 100px;
  min-height: 100px;
}

.ADD_ITEM_ACTION_BUTTON {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  min-height: 100px;
  background-color: var(--input-section-background);
  border: 1px solid var(--border-color);
  border-radius: 15px;
  margin-top: 2em;
  transition: all 0.5s ease-in-out;
}

.ADD_ITEM_ACTION_BUTTON > span {
  font-family: sans-serif;
  color: var(--font-color);
  height: 30px;
}

.ADD_ITEM_ACTION_BUTTON:hover {
  cursor: pointer;
  background-color: var(--background);
}

.ADD_ITEM_FORM_ACTIONS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  height: 100px;
  min-height: 100px;
}
