.JOB_IMAGES_MAIN_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 300px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 20px;
}

.JOB_IMAGES_ADD_BUTTON_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 300px;
  width: 200px;
}

/* Similar settings in the job card form */
.ADD_IMAGE_BUTTON {
  position: relative;
  overflow: hidden;
  display: inline-block;
  height: 100px;
  width: 100px;
  margin-left: 10px;
  border: 1px solid #2c3e50;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  font-size: 2.9rem;
  color: #6b778d;
}
.ADD_IMAGE_BUTTON:hover {
  cursor: pointer;
  border-radius: 50px;
}
.ADD_IMAGE_BUTTON input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.PROGRESS_CIRCLE_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}
/* -------------- */

.SAVE_IMAGE_BUTTON {
  position: relative;
  overflow: hidden;
  display: inline-block;
  height: 100px;
  width: 100px;
  margin-left: 10px;
  border: 1px solid #2c3e50;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  font-size: 2.9rem;
  color: #6b778d;
}
.SAVE_IMAGE_BUTTON:hover {
  cursor: pointer;
  border-radius: 50px;
}
.SAVE_IMAGE_BUTTON input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.SAVE_UPLOAD_FILE_ICON {
  display: flex;
  width: inherit;
  height: inherit;
  align-items: center;
  justify-content: center;
}
.JOB_IMAGES_ADDED_SAVED_LOADING {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  width: 60vw;
  border: 1px solid #34495e;
  opacity: 0.7;
  margin-bottom: 20px;
  border-radius: 20px;
  overflow: auto;
}
.JOB_IMAGES_ADDED_SAVED_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 320px;
  height: 340px;
  width: 60vw;
  /* border: 1px solid #34495e; */
  opacity: 0.7;
  padding-top: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
  overflow: auto;
}
.PREVIEW_MODAL_IMAGE_HEADER {
  display: block;
  width: max-content;
  padding-bottom: 10px;
}
.PREVIEW_MODAL_IMAGE_HEADER:hover {
  cursor: pointer;
}
.PREVIEW_MODAL_IMAGE_CONTAINER {
  display: block;
  max-height: 93%;
  max-width: 100%;
  resize: vertical;
  overflow: auto;
}

.PREVIEW_MODAL_IMAGE_CONTAINER img {
  display: block;
  width: 100%;
  height: auto;
}
