.TECHNICIAN_MAIN_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 100px;
  width: 100vw;
  min-width: max-content;
  background-color: var(--background);
}
.TECHNICIAN_SIDE_BAR_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 80vh;
  width: 20vw;
  min-width: 350px;
  max-width: 400px;
  padding-top: 20px;
  margin-left: 20px;
  border-radius: 20px;
  /* border: 1px solid #2c3e50; */
}
.TECHNICIAN_SEARCH_BAR_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15vw;
  max-width: 300px;
  min-width: 280px;
  height: 25px;
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  margin-bottom: 20px;
}
.TECH_JOB_TASKS_SIDE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 95%;
  width: 95%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* border: 1px solid #2c3e50; */
}
.TASK_CONTAINER {
  display: flex;
  width: 300px;
  height: 100px;
  border-radius: 20px;
  background-color: var(--border-color);
  opacity: 0.7;
  margin-bottom: 15px;
}

.TASK_SEARCH_INPUT_FIELD {
  color: var(--font-color) !important;
}

.TASK_SEARCH_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--background) inset !important;
  -webkit-text-fill-color: var(--font-color);
}

.TECHNICIAN_WORKSPACE_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 80vh;
  min-width: 1200px;
  width: 71vw;
  padding-top: 20px;
  margin-left: 20px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
