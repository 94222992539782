.COMPANY_BRANCH_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
}
.COMPANY_BRANCH_INPUT_FIELD_CONTAINER {
  margin-bottom: 20px;
}
.COMPANY_BRANCH_INPUT_FIELD {
  width: 300px !important;
  font-weight: 500;
  color: #17293e !important;
}
.COMPANY_BRANCH_INPUT_FIELD_LABEL {
  color: #182c43 !important;
}

.BRANCH_ITEM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 250px;
  width: max-content;
  height: 50px;
  background-color: #7fb7e4;
  border-radius: 5px;
  font-family: sans-serif;
  padding-inline: 0.5em;
}
