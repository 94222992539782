.ACCOUNTS_MANAGER_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 100%;
  width: max-content;
  min-height: 100vh;
  height: 100vh;
  margin: 0;
  background-color: var(--background);
  overflow: auto;
}

.ACCOUNTS_MANAGER_CONTAINER {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 1em;
  align-items: flex-start;
  justify-content: flex-start;
  height: 90%;
  min-width: 1200px;
  height: 85vh;
  padding-top: 85px;
}

.ACCOUNTS_MANAGER_ACTIONS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 150px;
  width: 10vw;
  /* min-height: 800px; */
  height: 80vh;
  border-radius: 20px;
  overflow: auto;
}

.ACCOUNTS_MANAGER_FORMS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 950px;
  width: 80vw;
  min-height: 800px;
  height: 85vh;
  border: 1px solid var(--border-color);
  border-radius: 20px;
}

.ACCOUNTS_MANAGER_FORMS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 950px;
  width: 98%;
  min-height: 750px;
  height: 98%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ACCOUNTS_MANAGER_GRAPHS_CONTAINER {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-width: 380px;
  min-height: 800px;
  height: 85vh;
  border: 1px solid var(--border-color);
  border-radius: 20px;
}

.ACCOUNTS_MANAGER_ACTIONS_BUTTON_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  min-height: 100px;
  color: #6b778d;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  margin-top: 1em;
  transition: all 0.5s ease-in-out;
}

.ACCOUNTS_MANAGER_ACTIONS_BUTTON_CONTAINER:hover {
  cursor: pointer;
  background-color: var(--background1);
}
