.STOCK_FORM_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: max-content;
  width: 100%;
}

.STOCK_FORM_HEADER_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
}

.STOCK_FORM_HEADER_CONTAINER > h1 {
  font-family: sans-serif;
  font-size: 1.8rem;
  color: var(--header-color);
}

.STOCK_FORM_FIELDS_MAIN_CONTAINER {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 45%);
  gap: 3em 2em;
  min-height: 400px;
  min-width: 1000px;
  width: 100%;
  height: max-content;
  margin-top: 2em;
  /* border: 1px solid #bbbbbb; */
}

.STOCK_FORM_INPUT_GROUP_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 350px;
  border-radius: 20px;
  background-color: var(--background2);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.STOCK_FORM_INPUT_FIELD_CONTAINER {
  padding: 5px;
  margin-top: 1em;
  width: 90%;
}

.STOCK_FORM_INPUT_FIELD {
  color: var(--font-color) !important;
  border-radius: 10px !important;
  /* height: 50px !important; */
  border: 0.01em solid var(--border-color) !important;
}
.STOCK_FORM_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--background2) inset !important;
  -webkit-text-fill-color: var(--font-color);
}

.STOCK_FORM_INPUT_FIELD_LABEL {
  color: var(--field-label) !important;
  z-index: 10 !important;
  background-color: var(--background2);
  padding-right: 10px !important;
  padding-left: 2px !important;
}

.STOCK_FORM_ACTIONS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  height: 100px;
}

.STOCK_TABLE_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 1000px;
  min-height: 300px;
  max-height: 600px;
  margin-top: 50px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.STOCK_TABLE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* border-radius: 10px; */
  /* padding: 10px; */
  width: 93%;
  min-height: 200px;
  margin-bottom: 100px;
}
