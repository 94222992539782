body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--border-color);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--background);
}

#dark {
  --background: #0b0f19;
  --background1: #233044;
  --background2: #183151;
  --input-section-background: #111827;
  --ticket-background: #082032;
  --hover-background: #1f2c39;
  --header-background1: #111f30;
  --header-background2: #040e1c;
  --shade-1: #132741;
  --shade-2: #193557;
  --header-color: #576cbc;
  --font-color: #ffffff;
  --secondary-font-color: #eeeeee;
  --temp-value-bg: #111f30;
  --field-label: #b8b8b8;
  --border-color: #384f68;
  --search-item-bg: #082032;
}

#light {
  --background: #dddddd;
  --background1: #ececec;
  --background2: #c7c7c7;
  --hover-background: #76a8d9;
  --input-section-background: #c3cfd1;
  --ticket-background: #06375d;
  --header-background1: #ececec;
  --header-background2: #c7c7c7;
  --shade-1: #bcbcbc;
  --shade-2: #fefefe;
  --header-color: #19376d;
  --font-color: #17293e;
  --secondary-font-color: #1c2d43;
  --temp-value-bg: #162e4c;
  --field-label: #182c43;
  --border-color: #bec3c7;
  --search-item-bg: #c3cfd1;
}

.ROOT_CONTAINER {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
}
