.ACCOUNT_STATMENT_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: max-content;
}

.ACCOUNT_STATMENT_HEADER_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 98%;
  height: 120px;
  margin-top: 1em;
  border-radius: 20px;
  background-color: var(--background1);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ACCOUNT_STATMENT_SEARCH_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 98%;
  min-width: 850px;
  height: 98%;
}

.ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD {
  color: var(--font-color) !important;
  border-radius: 10px !important;
  height: 50px !important;
  border: 0.01em solid var(--border-color) !important;
}

.ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--background1) inset !important;
  -webkit-text-fill-color: var(--font-color);
}

.ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD_LABEL {
  color: var(--font-color) !important;
  z-index: 10 !important;
  background-color: var(--background1);
  padding-right: 10px !important;
  padding-left: 2px !important;
}

.ACCOUNT_STATMENT_CLIENT_DETAILS_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 98%;
  height: 100px;
  margin-top: 1em;
  border-radius: 20px;
  background-color: var(--background1);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD {
  color: var(--font-color) !important;
  border-radius: 10px !important;
  height: 50px !important;
  border: 0.01em solid var(--border-color) !important;
}
.ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--background1) inset !important;
  -webkit-text-fill-color: var(--font-color);
}

.ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD_LABEL {
  color: var(--font-color) !important;
  z-index: 10 !important;
  background-color: var(--background1);
  padding-right: 10px !important;
  padding-left: 2px !important;
}

.ACCOUNT_STATMENT_TABLE_CONTAINER {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 98%;
  min-height: 200px;
  max-height: 500px;
  height: auto;
  border-radius: 20px;
  margin-top: 1em;
  overflow: auto;
  /* background-color: #111827; */
}

.ACCOUNT_STATMENT_RESULTS_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  min-height: 200px;
  height: auto;
  margin-top: 2em;
}

.ACCOUNT_STATMENT_TOTAL_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-width: 200px;
  width: 30%;
  min-height: 180px;
  height: 180px;
  border-radius: 20px;
  background-color: var(--background1);
}

.ACCOUNT_STATMENT_TOTAL_CONTAINER > h1 {
  font-family: sans-serif;
  color: var(--secondary-font-color);
}

.ACCOUNT_STATMENT_TOTAL_CONTAINER > span {
  font-family: Roboto;
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--field-label);
}

.ACCOUNT_STATMENT_PERIODS_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 98%;
  height: 150px;
  margin-top: 2em;
  border-radius: 20px;
  /* background-color: #111827; */
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ACCOUNT_STATMENT_PERIODS_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: max-content;
  height: 100%;
}
