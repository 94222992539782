.SIGNUP_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 1300px;
}

.SIGNUP_FORM_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  min-width: 300px;
  padding-top: 50px;
}
.SIGNUP_FORM_INPUT_FIELD_CONTAINER {
  padding: 5px;
  margin-bottom: 5px;
  width: 350px;
  min-width: 300px;
  height: 60px;
}
.SIGNUP_INPUT_FIELD {
  color: #162447 !important;
  border-radius: 10px !important;
  height: 50px;
}
.SIGNUP_INPUT_FIELD_LABEL {
  color: #6b778d !important;
  font-family: monospace !important;
  font-size: 0.85rem !important;
}
.SIGNUP_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #ffffff inset !important;
  -webkit-text-fill-color: #363a3b;
}
.form-control {
  width: 350px;
}
#signUpHeader {
  color: rgb(131, 131, 131) !important;
  font-family: Rubik;
  font-weight: bold;
  font-size: 1.1rem;
}

.SIGNUP_TERMS_CONTAINER {
  display: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 350px;
  min-width: 300px;
}
.SIGNUP_TERMS_CONTAINER h6 {
  width: 350px;
  font-family: cursive;
  font-size: 1rem;
}
.TERMS_LINK {
  color: #00909e;
  font-weight: 800;
}
.TERMS_LINK:hover {
  cursor: pointer;
}

#signUpHeader {
  color: rgb(215, 215, 219);
  margin-bottom: 20px;
}

#passwordMatchAlert {
  color: rgb(235, 83, 78);
  font-stretch: wider;
  font-weight: bold;
}

#wrongCredentials {
  color: rgb(235, 83, 78);
  font-stretch: wider;
  font-weight: bold;
  margin-bottom: 1em;
}

.form-group {
  /* border: 0.1rem solid rgb(219, 143, 120); */
  border-radius: 10px;
}
.form-signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.SIGNUP_FORM_ICON {
  color: #00909e;
  font-size: 8rem;
}

.SIGNUP_BUTTON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 45px;
  width: 150px !important;
  border-radius: 50px !important;
  box-shadow: 1px 1px 10px #315168;
}
.SIGNUP_BUTTON_CONTAINER h6 {
  font-size: 1.1rem;
  color: rgb(46, 44, 48);
  font-family: Roboto;
}

.SIGNUP_BUTTON_CONTAINER:hover {
  cursor: pointer;
}
