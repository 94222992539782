.COLLECT_DEVICE_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 98%;
  width: 100%;
  min-height: 1000px;
  height: max-content;
  transition: all 1s ease-in-out;
}

.EXISTED_CLIENT_SWITCH_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 70px;
  width: 200px;
  color: #5d6369;
}
.CLIENT_INSURANCE_DETAILS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 58vw;
  padding-top: 20px;
}
.ADD_ITEMS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 58vw;
  padding-top: 30px;
  /* border: 1px solid #fff; */
}
.ITEM_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 58vw;
}
.COLLECT_DEVICE_INPUT_FIELD {
  width: 300px !important;
  font-weight: 500;
  color: #a2aaad !important;
}
.COLLECT_DEVICE_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #1e272e inset !important;
  -webkit-text-fill-color: #a2aaad;
}

.COLLECT_DEVICE_INPUT_FIELD_LABEL {
  color: #6192bb !important;
}
.COLLECT_DEVICE_FORM_SELECT_CLIENT_INPUT_FIELD_CONTAINER {
  padding: 5px;
}
ul {
  background-color: #30475e;
  color: #a2aaad;
}
.COLLECT_DEVICE_FORM_S_INPUT_FIELD_CONTAINER {
  padding: 5px;
}
.COLLECT_DEVICE_FORM_ITEM_INPUT_FIELD_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 50%;
}
.ITEM_ADD_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  padding: 10px;
  margin-top: 30px;
  border-radius: 25px;
  box-shadow: 1px 1px 10px #041c32;
  color: #6b778d;
  font-family: Arial;
}
.COLLECT_DEVICE_FORM_COMMENT_INPUT_FIELD_CONTAINER {
  width: 400px;
}

.COLLECT_DEVICE_FORM_ACTION {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58vw;
  padding-top: 30px;
}
.COLLECT_DEVICE_FORM_ACTION_SAVE_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  width: 80px;
  height: 30px;
  border: 1px solid #2c3e50;
  border-radius: 5px;
  color: #77abb7;
  font-family: Arial;
  font-weight: 400;
}
.COLLECT_DEVICE_FORM_ACTION_CLEAR_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  border: 1px solid #2c3e50;
  border-radius: 5px;
  color: #77abb7;
  font-family: Arial;
  font-weight: 400;
}

.SAVED_ITEMS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 58vw;
  min-height: 320px;
  border: 1px solid #2c3e50;
  border-radius: 20px;
  margin-top: 30px;
}
.SAVED_ITEM_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 90%;
  border: 1px solid #2c3e50;
  border-radius: 15px;
  margin-top: 20px;
}

.SAVED_ITEM {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  width: 90%;
}
