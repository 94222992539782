.IMAGE_CARD_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 280px;
  border: 1px solid #34495e;
  border-radius: 20px;
}
.IMAGE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
}
.IMAGE_CONTAINER img {
  max-width: 90%;
  max-height: 230px;
}
.IMAGE_CARD_DELETE_ICON {
  display: flex;
  position: relative;
  font-size: 2rem;
  bottom: 10px;
  color: #ed6363;
}
.IMAGE_CARD_DELETE_ICON:hover {
  cursor: pointer;
}
